const consultants = [
    {
        id: '4447438',
        name: 'Thiago',
        email: 'thiago.andrade@contele.com.br',
        phone: '+5511981965590'
    },
    {
        id: '9771097',
        name: 'Gustavo',
        email: 'gustavo.nogueira@contele.com.br',
        phone: '+5511958624740'
    },
    {
        id: '6186902',
        name: 'Sheila Barros',
        email: 'sheila.barros@contele.com.br',
        phone: '+5513997036505'
    },
    {
        id: '11630177',
        name: 'Márcia Gabriele',
        email: 'marcia.gabriele@contele.com.br',
        phone: '+5511997296672'
    }
];

export { consultants };